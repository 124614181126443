import { render, staticRenderFns } from "./honoraireList.vue?vue&type=template&id=17f0197e&scoped=true"
import script from "./honoraireList.vue?vue&type=script&lang=js"
export * from "./honoraireList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f0197e",
  null
  
)

export default component.exports