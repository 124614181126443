<template>

        <div class="ui p-half clearing red segment">
            <!--<div>
                <h2 class="ui left floated header m-0"> Liste des Courtier </h2>
                <div class="ui right floated primary button" @click="newCourtier"> Nouveau </div>
            </div>-->

            <div class="downloadTab">
                <h3 class="ui left floated header m-0"> Liste des Honoraires </h3>
                <span class="right floated flex items-end" @click="newCourtier">
                <svg
                        width="20"
                        height="20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0)">
                        <path
                                d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                fill="#0953AA"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0h20v20H0z"/>
                        </clipPath>
                    </defs>
                </svg>
                Ajouter
            </span>
            </div>

            <div class="ui accordion">
                <div class="title">
                    <i class="dropdown icon"></i>
                    <u>Filtrer par:</u>
                </div>
                <div class="content">
                    <div class="ui segment">
                        <div class="ui equal width form">
                            <div class="two fields">
                                <div class="field">
                                    <label>Compagnie</label>
                                    <v-select :options="assurance_list" :reduce="option => option._id" v-model="searchQuery.id_compagnie" @input="getHonoraireList"
                                              label="label" style="text-transform: capitalize" placeholder="Compagnie">
                                    </v-select>
                                </div>
                                <div class="field">
                                    <label>Cadre expertise</label>
                                    <v-select :options="cadre_expertise_list" v-model="searchQuery.cadre_expertise" @input="getHonoraireList"
                                              label="username" style="text-transform: capitalize" placeholder="Cadre expertise">
                                    </v-select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <table class="ui selectable celled table">
                <thead>
                <tr>
                    <th class="collapsing">Actions</th>
                    <th @click="sortData('assurance.label')">
                        Compagnie
                        <i v-if="sort['assurance.label']" class="long arrow alternate icon float-right"
                           :class="sort['assurance.label'] === 1 ? 'up' : 'down'"></i>
                    </th>
                    <th>Cadre expertise </th>
                    <th>Montant </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!honoraireList.length"><td> Pas de données disponibles </td> </tr>
                <tr v-for="honoraire in honoraireList">
                    <td>
                        <div class="ui primary tiny button" @click="edit(honoraire)">Editer</div>
                    </td>
                    <td> {{ honoraire.assurance[0].label }}</td>
                    <td> {{ honoraire.cadre_expertise }}</td>
                    <td> {{ honoraire.montant }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="99">
                        <div>
                            <pagination :limit="limit" :current_page="page" :count="count" @setPage="setPage" slot="pagination"/>
                        </div>
                    </th>
                </tr>
                </tfoot>

            </table>

        </div>

</template>

<script>
    import pagination from "@/components/pagination.vue";
    export default {
        name: "honoraireList",
        components:{
            pagination
        },
        data() {
            return {
                assurance_list: [],
                honoraireList: [],
                page: 1,
                limit: 10,
                count: 0,
                searchQuery:{
                    id_compagnie:null,
                    cadre_expertise:null
                },
                sort: {'assurance.label': 1}
            }
        },
        computed:{
            cadre_expertise_list(){
                return this.$store.state.cadre_expertise;
            }
        },
        methods: {
            getHonoraireList() {
                log('getHonoraireList');
                let match ={};
                if(this.searchQuery.id_compagnie){
                    match.id_compagnie = this.searchQuery.id_compagnie;
                }
                if(this.searchQuery.cadre_expertise){
                    match.cadre_expertise = {$regex: this.searchQuery.cadre_expertise}
                }
                let data = {
                    collection: this.$SHARED.collections.honoraire,
                    query: match,
                    concat_pipeline: [
                        {
                            $lookup: {
                                from: "assurance",
                                localField: "id_compagnie",
                                foreignField: "_id",
                                as: "assurance"
                            }
                        }
                    ],
                    options: {
                        page: parseInt(this.page),
                        limit: parseInt(this.limit)
                    }
                };
                if (!_.isEmpty(this.sort)) {
                    data.options.sort = JSON.parse(JSON.stringify(this.sort))
                }

                this.$store.requestFind(data).then(ar => {
                    this.honoraireList = ar.docs;
                    this.count = ar.count;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            sortData(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }

                this.getHonoraireList();
            },
            newCourtier(){
                this.$router.push({name: 'honoraireCrud'});
            },
            edit(current_honoraire){
                this.$router.push({name: 'honoraireCrud', params:{current_honoraire}});
            },
            setPage(page){
                this.page = page;
                this.getHonoraireList();
            },
            getAssurances() {
                log('getAssurances');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {
                        page: 1,
                        limit: -1,
                        sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        mounted() {
            this.getHonoraireList();
            this.getAssurances();
            $('.ui.accordion').accordion({exclusive:true});
        }
    }
</script>

<style scoped>

</style>